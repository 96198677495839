/* montserrat-regular - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../../fonts/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local(""),
    url("../../fonts/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("../../fonts/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

* {
  font-family: "Montserrat", sans-serif !important;
}

img {
  max-width: 100%;
}

a {
  color: #222222;
  text-decoration: none;
}

.cursor {
  cursor: pointer;
}

.trans {
  -webkit-transition: 0.3s cubic-bezier(0.2, 0.61, 0.41, 0.95);
  transition: 0.3s cubic-bezier(0.2, 0.61, 0.41, 0.95);
}

#simple-tabpanel-0,
#simple-tabpanel-1 {
  & > div:nth-child(1) {
    padding: 0;
    margin-top: 8px;
  }
}

#panel1a-content {
  & > div:nth-child(1) {
    padding: 0;
  }
}

.mg {
  &.m0 {
    margin: 0;
  }

  &.t1 {
    margin-top: 0.25rem;
  }

  &.t2 {
    margin-top: 0.5rem;
  }

  &.t3 {
    margin-top: 0.75rem;
  }

  &.t4 {
    margin-top: 1rem;
  }

  &.t5 {
    margin-top: 2rem;
  }

  &.b0 {
    margin-bottom: 0;
  }

  &.b1 {
    margin-bottom: 0.25rem;
  }

  &.b2 {
    margin-bottom: 0.5rem;
  }

  &.b3 {
    margin-bottom: 0.75rem;
  }

  &.b4 {
    margin-bottom: 1rem;
  }

  &.b5 {
    margin-bottom: 2rem;
  }

  &.r1 {
    margin-right: 0.25rem;
  }

  &.r2 {
    margin-right: 0.5rem;
  }

  &.r3 {
    margin-right: 0.75rem;
  }

  &.r4 {
    margin-right: 1rem;
  }

  &.r5 {
    margin-right: 2rem;
  }
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.f_normal {
  font-weight: normal !important;
}

.f_size {
  &.large {
    font-size: large;
  }
}

.barr {
  background: cornflowerblue;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiFilledInput-underline {
  border-radius: 4px;

  &:after {
    border-bottom: 0 !important;
  }

  &:before {
    border: 0 !important;
  }
}

.MuiLinearProgress-root.MuiLinearProgress-colorPrimary.item_bar {
  background: #65707d !important;
}

.input_select,
.box_form_detail,
.wrapper_branch {
  .MuiFilledInput-root {
    background: transparent !important;
  }

  .MuiSelect-filled.MuiFilledInput-input {
    border: 1px solid #aaaaaa;
    border-radius: 10px;
    padding: 10px !important;
  }

  .MuiInputLabel-filled {
    z-index: 1;
    transform: translate(12px, 13px) scale(1);
    pointer-events: none;

    &.MuiInputLabel-shrink {
      transform: translate(11px, -6px) scale(0.75) !important;
      background: #fff !important;
      padding: 0 10px !important;
      display: none;
    }
  }
}

.button-component {

  .MuiButton-root {
    border-radius: 30px !important;
    padding: 10px 16px !important;
    min-width: 125px !important;
    text-transform: uppercase !important;
  }
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.wrapper_table {
  .box_name_th {
    display: flex;
    align-items: center;
  }
}

.link_user {
  color: #23a4ed;
}

.wrapper_table_list {

  span.MuiButtonBase-root {
    color: #ffffff;
    font-size: 1rem;
  }

  .wrapper_table {
    .MuiTableHead-root {
      border-radius: 10px !important;

      .MuiTableRow-head {
        border-radius: 10px !important;

        .MuiTableSortLabel-root {
          .MuiTableSortLabel-icon {
            opacity: 1 !important;
          }
        }

        .MuiTableCell-root {
          border: 0 !important;

          &.MuiTableCell-head {
            &:first-child {
              border-radius: 10px 0 0 10px !important;
            }

            &:last-child {
              border-radius: 0 10px 10px 0 !important;
            }
          }
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        &:nth-child(2n) {
          background: #f3f3f3 !important;
          border: 0;
        }

        .MuiTableCell-root {
          border: 0;
        }
      }
    }
  }
}

// === WRAPPER MAIN ===
.wrapper_main {
  margin: 0 auto;
  min-height: calc(100vh - 140px);

  .section_main {
    margin-top: 60px;
    padding: 1rem 1.5rem;
  }
}

@media screen and (max-width: 1080px) {
  .wrapper_user_details {
    .content_user_details {
      .box_view_thumbnails {
        .box_view_detail {
          flex-wrap: wrap;

          & > div:last-child {
            margin-top: 2rem;
          }

          .fieldset_form_detail {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper_user_details {
    flex-wrap: wrap;

    .aside_info_user {
      width: 100% !important;
    }

    .sec_user_details {
      width: 100% !important;
      top: auto !important;
    }

    .fieldset_form_detail .content_user_details {
      .box_view_thumbnails {
        .box_view_detail {
          .fieldset_form_detail {
            .box_form_detail .MuiFormGroup-row {
              flex-wrap: wrap !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .ttls_ist_sucursales {
    display: none !important;
  }
}

@media screen and (max-width: 420px) {
  .ttl_label {
    line-height: 1.2;
    font-size: 1.15rem !important;
    margin-bottom: 0.35rem !important;
  }
}

.center-spinner {
  width: 100px;
  height: 100px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.style-test {
  font-family: "Lobster";
}

.fieldset_form_detail {
  border-radius: 8px;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
  border: 2px solid #cccccc;

  legend {
    font-size: 1rem;
    font-weight: 600;
    padding: 0 0.5rem;
  }

  .box_form_detail {
    .MuiFormControl-root.MuiTextField-root {
      margin: 0;
    }

    .MuiFilledInput-multiline {
      padding: 5px;
    }

    .MuiFormGroup-row {
      justify-content: space-evenly;
      flex-wrap: wrap;
    }

    .MuiFormControl-root {
      margin-top: 1rem;
    }

    .MuiFormControlLabel-label {
      line-height: 0.9;
      font-size: 0.9rem;
      color: #666666;
    }
  }
}
