.wrapper_user_details {
  margin-top: 0.75rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: flex-start;

  .aside_info_user {
    width: 250px;
    min-width: 250px;
    margin-right: 1rem;

    .name_info_user {
      display: flex;
      line-height: 1;

      & > span {
        min-width: 30px;
      }
    }
  }

  .sec_user_details {
    position: relative;
    top: -17px;
    width: calc(100% - 250px);
  }

  .content_user_details {
    position: relative;

    .arrows {
      z-index: 9;
      position: absolute;
      top: 75px;
      cursor: pointer;

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }
    }

    .box_list_thumbnails {
      scroll-behavior: smooth;
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      overflow: auto;
      margin: 0 auto;
      padding: 1rem 1rem 0.8rem 0.8rem;

      .item_thumbnail {
        & > div {
          margin-right: 1.75rem;
          background: #ffffff;
          cursor: pointer;
          border-radius: 0.5rem;
          line-height: 0;
          position: relative;
          width: 150px;
          min-width: 150px;
          box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);

          &.active,
          &:hover {
            box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.6);
            img {
              opacity: 1;
            }
          }

          img {
            border-radius: 0.75rem;
            opacity: 0.5;
          }
        }
      }
    }

    .box_view_thumbnails {
      padding-right: 1rem;

      .box_view_detail {
        display: flex;

        div {
          // width: 100%;

          // margin-right: 1rem;

          img {
            width: 100%;
            border-radius: 1rem;
            object-fit: cover;
          }
        }

        .box_view_magnifier {
          width: 120%;
          .box_magnifier {
            position: relative;
          }
          .label {
            color: rgb(183, 183, 183);
          }
          .preprocessed {
            position: absolute;
            right: 0px;
            top: 5px;
          }
        }
      }
    }

    .content_eyes {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      flex: 1 1 100%;
      box-sizing: border-box;
      max-width: 26.6%;
      height: 150px;
    }

    .fieldset_form_detail {
      margin-top: 20px;
    }
  }
}

.arrow-style {
  position: absolute;
  z-index: 9999999;
}

.MuiDivider-root {
  height: 2px !important;
}

@media screen and (max-width: 1081px) {
  .content_user_details {
    .content_eyes {
      max-width: 350px !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .item-content {
    display: flex;
    justify-content: center;
    button {
      margin-bottom: 10px;
    }
  }

  .content_user_details {
    .content_eyes {
      flex-direction: column !important;
      height: auto !important;
      max-width: fit-content !important;
    }
    .box_list_thumbnails {
      align-items: center;
    }
    .box_view_thumbnails {
      margin-top: 1rem;
    }
  }
}
