.wrapper_login{
  min-height: calc(100vh - 85px);
  display: flex;
  justify-content: center;
  align-items: center;
  .box_form{
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    font-size: 1.25rem;
    padding: 0 1rem;
  }
}

.error-login{
  color: red;
  font-size: 1rem;
}