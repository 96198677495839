.wrapper_home {
  .accordion_box {
    .MuiAccordion-root {
      .MuiAccordionSummary-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffffff;
        &>.labels {
          font-size: 1.1rem;
        }
      }
    }
  }
}
