.wrapper_header {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 60px;
  z-index: 99;
  img {
    height: 50px;
  }
  .btn_logout {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #ffffff;
  }
}
