.wrapper_footer{
  padding-top: .6rem;
  height: 70px;
  margin-top: 1rem;
  .cont_img{
    max-width: 1360px;
    width: 100%;
    margin: 0 auto;
    text-align: right;
    img{
      height: 50px;
    }
  }
}